/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Recommends from '../components/Recommends';

const CenterH1 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{children}</h1>
    </div>
  );
};
const CenterH2 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{children}</h2>
    </div>
  );
};
const CenterH3 = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{children}</h3>
    </div>
  );
};

const Center = ({ children }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>{children}</p>
    </div>
  );
};

const PostFooter = () => {
  return <p style={{ textAlign: 'end' }}>https://www.huiju.cool/</p>;
};

const CenterImage = ({ src, widthRatio = 100 }) => {
  return (
    <div style={{ width: `${widthRatio}%`, margin: '0 auto' }}>
      <img src={src} alt="pic" />
    </div>
  );
};

const Link = ({ children, href = 'https://www.huiju.cool/' }) => (
  <a href={href} style={{ color: '#484848' }}>
    {children}
  </a>
);

const shortcodes = {
  CenterH1,
  CenterH2,
  CenterH3,
  Center,
  CenterImage,
  PostFooter,
  Link,
};

const PostLayout = ({ data: { mdx }, pageContext }) => {
  console.log(pageContext);
  const { prev, next } = pageContext;

  return (
    <Layout current={mdx.frontmatter.type + 's'} type="blogs">
      <Helmet>
        <title>{mdx.frontmatter.title}-荟聚</title>
        <meta name="keywords" content={mdx.frontmatter.keywords} />
        <meta name="description" content={mdx.frontmatter.description} />
      </Helmet>
      <div className="postContainer">
        <div className="post">
          <MDXProvider components={shortcodes}>
            <h1>{mdx.frontmatter.title}</h1>
            <div className="authorContainer">
              <span className="name">{mdx.frontmatter.author}</span>
              <span className="date">{mdx.frontmatter.date}</span>
            </div>
            {!mdx.frontmatter.coverHidden && mdx.frontmatter.cover && (
              <img className="coverImage" alt={mdx.frontmatter.title} src={mdx.frontmatter.cover} />
            )}
            {!mdx.frontmatter.coverHidden && !mdx.frontmatter.cover && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href="https://www.huiju.cool" target="_blank">
                <img
                  className="coverImage"
                  alt={mdx.frontmatter.title}
                  src="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/template/jike/WX20220113-195216%402x.png"
                />
              </a>
            )}
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
          <div className="post-pagination">
            <nav className="pagination-nav" aria-label="Blog list page navigation">
              <div className="pagination-nav__item">
                {prev && (
                  <a className="pagination-nav__link" href={prev.fields.slug}>
                    <div className="pagination-nav__sublabel">上一篇</div>
                    <div className="pagination-nav__label">« {prev.frontmatter.title}</div>
                  </a>
                )}
              </div>
              <div className="pagination-nav__item pagination-nav__item--next">
                {next && (
                  <a className="pagination-nav__link" href={next.fields.slug}>
                    <div className="pagination-nav__sublabel">下一篇</div>
                    <div className="pagination-nav__label">{next.frontmatter.title} »</div>
                  </a>
                )}
              </div>
            </nav>
          </div>
        </div>
        <div className="sider">
          <div className="qrcode">
            <div className="_cl_qrcode_article" style={{ width: 160 }} />
            <div className="desc">关注获取更多营销咨询</div>
          </div>
          <Recommends />
        </div>
      </div>
    </Layout>
  );
};
export default PostLayout;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        author
        date
        cover
        coverHidden
        keywords
        description
        type
      }
    }
  }
`;
