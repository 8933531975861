// /* eslint-disable react/prop-types */
import React from 'react';
import useRecommendsData from '../hooks/use-recommends';
import PostIcon from '../images/post-icon.png';
import { useSearch } from '../utils/common';

function Recommends() {
  const data = useRecommendsData();
  const search = useSearch();

  if (!data.length) {
    return null;
  }

  return (
    <div className="recommend">
      <div className="title">推荐阅读</div>
      <div className="recommentList">
        {data.map((node) => {
          return (
            <div className="recommentItem" key={node.id}>
              <img src={PostIcon} alt="post-icon" />
              <a href={`/blog/${node.slug}/${search}`}>{node.frontmatter.title}</a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Recommends;
